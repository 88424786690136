var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"liveFunList"},[_c('el-tabs',{staticClass:"main_content",model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"全部","name":"first"}},[_c('div',{staticClass:"all_fun fun_list"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/image/FunList/icon-3.png"),"alt":""}})]),_c('p',[_vm._v("运营类")])]),_vm._l((_vm.FunImgList.filter((item) => item.type == 0)),function(item){return _c('div',{key:item.imgId,staticClass:"fun_item",attrs:{"id":'img_' + item.imgId}},[_c('router-link',{attrs:{"to":{
              path: '/workbench',
              query: { workID: item.imgId >= 20 ? 2 : item.imgId },
            }}},[_c('img',{class:'img_' + item.imgId,staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url}})])],1)})],2),_c('div',{staticClass:"all_fun fun_list"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/image/FunList/icon-1.png"),"alt":""}})]),_c('p',[_vm._v("生活类")])]),_vm._l((_vm.FunImgList.filter((item) => item.type == 1)),function(item){return _c('div',{key:item.imgId,staticClass:"fun_item",attrs:{"id":'img_' + item.imgId}},[_c('router-link',{attrs:{"to":{
              path: '/workbench',
              query: { workID: item.imgId >= 21 ? 2 : item.imgId },
            }}},[_c('img',{class:'img_' + item.imgId,staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url}})])],1)})],2),_c('div',{staticClass:"all_fun fun_list"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../assets/image/FunList/icon-2.png"),"alt":""}})]),_c('p',[_vm._v("文学类")])]),_vm._l((_vm.FunImgList.filter((item) => item.type == 2)),function(item){return _c('div',{key:item.imgId,staticClass:"fun_item",attrs:{"id":'img_' + item.imgId}},[_c('router-link',{attrs:{"to":{
              path: '/workbench',
              query: { workID: item.imgId >= 21 ? 2 : item.imgId },
            }}},[_c('img',{class:'img_' + item.imgId,staticStyle:{"width":"100%","height":"100%"},attrs:{"src":item.url}})])],1)})],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }