<template>
  <div class="liveFunList">
    <el-tabs class="main_content" v-model="activeName">
      <el-tab-pane label="全部" name="first">
        <div class="all_fun fun_list">
          <div class="title">
            <div class="icon">
              <img src="../assets/image/FunList/icon-3.png" alt="" />
            </div>
            <p>运营类</p>
          </div>
          <div
            class="fun_item"
            :id="'img_' + item.imgId"
            v-for="item in FunImgList.filter((item) => item.type == 0)"
            :key="item.imgId"
          >
            <router-link
              :to="{
                path: '/workbench',
                query: { workID: item.imgId >= 20 ? 2 : item.imgId },
              }"
            >
              <img
                style="width: 100%; height: 100%"
                :class="'img_' + item.imgId"
                :src="item.url"
              />
            </router-link>
          </div>
        </div>
        <div class="all_fun fun_list">
          <div class="title">
            <div class="icon">
              <img src="../assets/image/FunList/icon-1.png" alt="" />
            </div>
            <p>生活类</p>
          </div>
          <div
            class="fun_item"
            :id="'img_' + item.imgId"
            v-for="item in FunImgList.filter((item) => item.type == 1)"
            :key="item.imgId"
          >
            <router-link
              :to="{
                path: '/workbench',
                query: { workID: item.imgId >= 21 ? 2 : item.imgId },
              }"
            >
              <img
                style="width: 100%; height: 100%"
                :class="'img_' + item.imgId"
                :src="item.url"
              />
            </router-link>
          </div>
        </div>
        <div class="all_fun fun_list">
          <div class="title">
            <div class="icon">
              <img src="../assets/image/FunList/icon-2.png" alt="" />
            </div>
            <p>文学类</p>
          </div>
          <div
            class="fun_item"
            :id="'img_' + item.imgId"
            v-for="item in FunImgList.filter((item) => item.type == 2)"
            :key="item.imgId"
          >
            <router-link
              :to="{
                path: '/workbench',
                query: { workID: item.imgId >= 21 ? 2 : item.imgId },
              }"
            >
              <img
                style="width: 100%; height: 100%"
                :class="'img_' + item.imgId"
                :src="item.url"
              />
            </router-link>
          </div>
        </div>
      </el-tab-pane>
      <!-- <el-tab-pane label="收藏" name="second">收藏</el-tab-pane>
      <el-tab-pane label="常用" name="third">常用</el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      activeName: "first",
      FunImgList: [
        { imgId: 2, type: 0, url: require("../assets/image/FunList/02.png") },
        { imgId: 9, type: 1, url: require("../assets/image/FunList/09.png") },
        { imgId: 8, type: 0, url: require("../assets/image/FunList/08.png") },
        { imgId: 1, type: 0, url: require("../assets/image/FunList/05.png") },
        { imgId: 4, type: 0, url: require("../assets/image/FunList/04.png") },
        { imgId: 6, type: 0, url: require("../assets/image/FunList/06.png") },
        { imgId: 7, type: 0, url: require("../assets/image/FunList/07.png") },
        { imgId: 10, type: 1, url: require("../assets/image/FunList/10.png") },
        { imgId: 11, type: 1, url: require("../assets/image/FunList/11.png") },
        { imgId: 12, type: 1, url: require("../assets/image/FunList/12.png") },
        { imgId: 13, type: 1, url: require("../assets/image/FunList/14.png") },
        // { imgId: 14, url: require("../assets/image/FunList/13.png") },
        { imgId: 15, type: 1, url: require("../assets/image/FunList/15.png") },
        { imgId: 16, type: 1, url: require("../assets/image/FunList/16.png") },
        { imgId: 17, type: 1, url: require("../assets/image/FunList/17.png") },
        { imgId: 18, type: 1, url: require("../assets/image/FunList/18.png") },
        { imgId: 19, type: 1, url: require("../assets/image/FunList/19.png") },
        { imgId: 20, type: 1, url: require("../assets/image/FunList/20.png") },
        { imgId: 21, type: 2, url: require("../assets/image/FunList/13.png") },
        { imgId: 22, type: 2, url: require("../assets/image/FunList/13.png") },
        
        
        // { imgId: 15, url: require("../assets/image/FunList/13.png") },
      ],
    };
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
$one-color: rgba(29, 211, 65, 0.5);
$two-color: rgba(255, 96, 126, 0.5);
$three-color: rgba(48, 84, 94, 0.5);
$four-color: rgba(111, 89, 255, 0.5);
$five-color: rgba(255, 173, 85, 0.5);
$six-color: rgba(86, 123, 255, 0.5);
$seven-color: rgba(116, 85, 255, 0.5);
$eight-color: rgba(33, 211, 137, 0.5);
$nine-color: rgba(244, 76, 157, 0.55);
$ten-color: rgba(255, 98, 0, 0.5);
.main_content {
  margin-top: 1.875rem;

  .el-tabs__nav-scroll {
    padding: 0 0 0 2.5rem;
  }

  .el-tabs__item {
    font-size: 1.125rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 1.5625rem;
  }

  .all_fun {
    margin-top: 1.875rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    .title {
      width: 100%;
      height: 2rem;
      margin: 0 0 2rem 0;
      box-sizing: border-box;
      padding: 0 0 0 1rem;
      display: flex;
      .icon {
        height: 100%;
        width: 2rem;
        display: flex;
        align-content: center;
        img {
          width: 100%;
          height: 100%;
        }
      }
      p {
        font-size: 1.5rem;
        font-weight: 400;
        color: #191f33;
        line-height: 2.1rem;
        margin: 0 0 0 0.625rem;
      }
    }
    .fun_item:hover {
      transform: translateY(-1rem);
      transition: 0.5s ease;
    }
    .fun_item {
      width: 12rem;
      height: 12rem;
      margin-right: 1.5rem;
      margin-left: 1rem;
      transition: 0.5s ease;
      img {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 3rem !important;
      }

      div:first-child {
        border-radius: 3rem;
        width: 13rem;
        height: 13rem;
        transition: 0.5s ease;
      }
      margin-bottom: 2.5rem;
    }

    .fun_item {
      .img_1:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $five-color;
      }
      .img_2:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $two-color;
      }
      .img_3:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $eight-color;
      }
      .img_4:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $four-color;
      }
      .img_5:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $three-color;
      }
      .img_6:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
      .img_7:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
      .img_8:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $one-color;
      }
      .img_9:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $five-color;
      }
      .img_10:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
      .img_11:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $nine-color;
      }
      .img_12:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
      .img_13:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $nine-color;
      }
      // .img_14:hover {
      //   box-shadow: 0 0.25rem 1rem 0.1rem $ten-color;
      // }
      .img_15:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $one-color;
      }
      .img_16:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
      .img_17:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $five-color;
      }
      .img_18:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $one-color;
      }
      .img_19:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $five-color;
      }
      .img_20:hover {
        box-shadow: 0 0.25rem 1rem 0.1rem $six-color;
      }
    }
  }
  .el-tabs__content {
    margin-left: 2.5rem;
  }
  th {
    background-color: #f5f6fc;
    color: #a4abc0;
  }
  tr td:last-child {
    font-weight: 900;
    cursor: pointer;
  }
}
</style>
